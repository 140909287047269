'use client'

import { useEffect } from 'react'
import NProgress from 'nprogress'
import StyledProgressBar from './styles'

type PushStateInput = [data: any, unused: string, url?: string | URL | null | undefined]

export default function ProgressBar() {
  useEffect(() => {
    NProgress.configure({ showSpinner: false })

    const handleAnchorClick: EventListener = (event) => {
      // Here, assert the type to MouseEvent since we know it's from a click event
      const mouseEvent = event as MouseEvent
      const anchorElement = mouseEvent.currentTarget as HTMLAnchorElement
      const targetUrl = new URL(anchorElement.href)
      const currentUrl = new URL(window.location.href)

      if (targetUrl.hostname === currentUrl.hostname && targetUrl.href !== currentUrl.href) {
        NProgress.start()
      }
    }

    const handleMutation: MutationCallback = () => {
      const anchorElements: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[href]')

      anchorElements.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick) // Remove existing listener to avoid duplicates
        anchor.addEventListener('click', handleAnchorClick)
      })
    }

    const mutationObserver = new MutationObserver(handleMutation)

    mutationObserver.observe(document, { childList: true, subtree: true })

    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray: PushStateInput) => {
        // Ensure NProgress is completed when pushState is used within the app
        NProgress.done()
        target.apply(thisArg, argArray)
      },
    })

    // Cleanup function to remove the observer and event listeners
    return () => {
      mutationObserver.disconnect()
      document.querySelectorAll('a[href]').forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick)
      })
    }
  }, []) // Empty dependency array means this effect runs only once after the initial render

  return <StyledProgressBar />
}
